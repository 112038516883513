function Creativity({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M52.9447 25.1997C53.6284 22.9111 47.8426 19.2504 42.6034 19.022C32.4443 18.6105 19.1423 23.9639 18.049 35.7234C17.2283 45.1504 26.8874 50 33.9939 50C41.1457 50 47.9344 44.4636 48.6633 41.4428C49.3922 38.3313 42.1486 39.201 42.6501 34.2591C43.379 27.2585 51.9433 28.5397 52.9462 25.1983L52.9447 25.1997ZM42.1938 23.5993C42.1938 24.7897 41.2827 25.7049 40.1442 25.7049C38.959 25.7049 38.0479 24.7897 38.0479 23.5993C38.0479 22.4557 38.959 21.5405 40.1442 21.5405C41.2827 21.5405 42.1938 22.4557 42.1938 23.5993ZM36.545 24.6975C36.545 25.9787 35.5421 26.9861 34.3132 26.9861C33.0843 26.9861 32.0814 25.9787 32.0814 24.6975C32.0814 23.507 33.0843 22.5011 34.3132 22.5011C35.5421 22.5011 36.545 23.5085 36.545 24.6975ZM31.1236 27.6714C31.1236 28.998 30.1207 30.0508 28.8 30.0508C27.5244 30.0508 26.4763 28.998 26.4763 27.6714C26.4763 26.4356 27.5244 25.4296 28.8 25.4296C30.1207 25.4296 31.1236 26.437 31.1236 27.6714ZM26.5681 32.8871C26.5681 34.2138 25.4748 35.312 24.1541 35.312C22.7415 35.312 21.6482 34.2138 21.6482 32.8871C21.6482 31.4683 22.7415 30.3701 24.1541 30.3701C25.4748 30.3701 26.5681 31.4683 26.5681 32.8871ZM39.0961 41.6727C39.0961 43.4123 37.6383 44.8297 35.8613 44.8297C34.1295 44.8297 32.7184 43.4108 32.7184 41.6727C32.7184 39.8878 34.131 38.3781 35.8613 38.3781C37.6383 38.3781 39.0961 39.8878 39.0961 41.6727Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default Creativity;

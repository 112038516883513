function SelfCare({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M50.3744 39.1862L50.7412 35.7768C50.9368 33.9578 51.066 32.7566 50.9646 31.9998L51 32C52.6568 32 54 30.6568 54 29C54 27.3431 52.6568 26 51 26C49.3432 26 48 27.3431 48 29C48 29.7493 48.2748 30.4345 48.729 30.9602C48.0768 31.3628 47.2244 32.212 45.941 33.4902C44.9524 34.475 44.458 34.9674 43.9066 35.0438C43.601 35.086 43.2898 35.0426 43.0076 34.9184C42.4984 34.694 42.1588 34.0854 41.4798 32.868L37.901 26.4508C37.482 25.6997 37.1314 25.0711 36.8154 24.5653C38.112 23.9037 39 22.5556 39 21C39 18.7909 37.2092 17 35 17C32.7908 17 31 18.7909 31 21C31 22.5556 31.888 23.9037 33.1846 24.5653C32.8686 25.0712 32.518 25.6997 32.099 26.4508L28.5201 32.868C27.8411 34.0854 27.5016 34.694 26.9924 34.9184C26.7103 35.0426 26.3989 35.086 26.0934 35.0438C25.5419 34.9674 25.0476 34.475 24.059 33.4902C22.7757 32.212 21.9231 31.3628 21.2711 30.9602C21.7253 30.4345 22 29.7493 22 29C22 27.3431 20.6569 26 19 26C17.3431 26 16 27.3431 16 29C16 30.6568 17.3431 32 19 32L19.0354 31.9998C18.934 32.7566 19.0632 33.9578 19.2589 35.7768L19.6256 39.1862C19.8292 41.0786 19.9984 42.8792 20.2057 44.5H49.7942C50.0016 42.8792 50.1708 41.0786 50.3744 39.1862Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M32.8241 53H37.1757C42.8477 53 45.6835 53 47.5757 51.3064C48.4017 50.567 48.9245 49.2344 49.3019 47.5H20.6979C21.0753 49.2344 21.5982 50.567 22.4241 51.3064C24.3163 53 27.1522 53 32.8241 53Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default SelfCare;

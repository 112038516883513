function HelpingOthers({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 51L32 48.5C32.6937 47.3279 35.228 47.7216 35.7852 47.3976C36.3424 47.0735 37.0438 47.0613 38.4469 47.0372C40.5183 47.0014 41.8173 46.8745 42.9068 46.4233C44.9284 45.5861 46.5343 43.98 47.3717 41.9584C47.7461 41.0547 47.8972 40.0068 47.9583 38.4934C45.4932 39.7456 42.7039 40.4513 39.7498 40.4513C29.7259 40.4513 21.5999 32.3255 21.5999 22.3015C21.5999 21.2632 21.6871 20.2453 21.8546 19.2546C20.6745 19.4286 19.7475 19.7221 18.9394 20.2173C17.8293 20.8975 16.896 21.8308 16.2157 22.9409C15 24.9248 15 27.6253 15 33.0264V34.6764C15 38.5203 15 40.4424 15.628 41.9584C16.4653 43.98 18.0713 45.5861 20.0928 46.4233C21.1823 46.8745 22.4814 47.0014 24.5527 47.0372C25.9558 47.0613 26.9428 47.1759 27.5 47.5C28.0572 47.8241 28 48 28 48L28.5 49.5L27 51C27.7973 52.3469 30 51 30 51Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M33.9747 19H29.0248C25.8238 19 23.5714 19 21.8545 19.2531C21.687 20.2437 21.5999 21.2617 21.5999 22.3C21.5999 32.3238 29.7259 40.4498 39.7497 40.4498C42.7038 40.4498 45.4931 39.7439 47.9582 38.4919C47.9996 37.4665 47.9996 36.2275 47.9996 34.6748V33.0249C47.9996 27.6238 47.9996 24.9232 46.7839 22.9394C46.1036 21.8293 45.1704 20.896 44.0603 20.2157C42.0763 19 39.3758 19 33.9747 19Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M42.6207 50.0703L42.639 50.2281L42.745 50.3464L43.3481 51.0197C43.1448 51.2069 42.8862 51.3069 42.5892 51.3369C42.1554 51.3807 41.6574 51.2691 41.224 51.052L41.2236 51.0518L40.282 50.581L39.2726 49.6901C39.0223 49.2673 38.8151 48.9195 38.6168 48.6474C38.4018 48.3524 38.1755 48.1147 37.875 47.94C37.5729 47.7643 37.249 47.6855 36.88 47.6446C36.5251 47.6053 36.0905 47.5979 35.5572 47.5887L35.5351 47.5883C33.903 47.5601 32.9658 47.4582 32.2039 47.1426L32.2039 47.1426C30.7337 46.5337 29.5657 45.3656 28.9567 43.8954L28.9567 43.8954C28.7388 43.3692 28.6208 42.7528 28.5607 41.8718C28.5003 40.9863 28.5 39.8705 28.5 38.3493V37.0493C28.5 34.9119 28.5005 33.344 28.6175 32.1139C28.7337 30.8913 28.9615 30.0541 29.3842 29.3644C29.8789 28.557 30.5577 27.8783 31.365 27.3835C31.927 27.0391 32.5872 26.8242 33.4735 26.6935L33.4931 26.6907L33.5123 26.6862C38.2285 25.6052 45.2202 25.5547 52.4019 26.9903C52.4749 27.0049 52.6374 27.0962 52.8299 27.498C53.0125 27.8791 53.1676 28.4301 53.2919 29.1208C53.5392 30.4962 53.6442 32.305 53.6712 34.1233C53.6982 35.9362 53.6476 37.7361 53.5901 39.0844C53.5613 39.7581 53.5309 40.318 53.5077 40.7093C53.4961 40.9048 53.4863 41.0582 53.4794 41.1624L53.4714 41.2811L53.4693 41.3112L53.4688 41.3186L53.4686 41.3204L53.4686 41.3208L53.9673 41.3567L53.4686 41.3209L53.468 41.3287L53.4677 41.3366C53.4203 42.5126 53.3037 43.2666 53.0432 43.8954L53.0432 43.8954C52.4342 45.3657 51.2663 46.5337 49.7961 47.1426L49.796 47.1426C49.0341 47.4582 48.0969 47.5601 46.4648 47.5883L46.4427 47.5887C45.9094 47.5979 45.4748 47.6053 45.1199 47.6446C44.7509 47.6855 44.427 47.7643 44.125 47.94C44.0942 47.9578 44.0534 47.9798 44.0053 48.0057C43.8014 48.1156 43.4654 48.2966 43.2004 48.5394C42.8368 48.8726 42.5395 49.3686 42.6207 50.0703Z"
        fill={pathFill}
        stroke={circleFill}
        className="transition"
      />
    </svg>
  );
}

export default HelpingOthers;

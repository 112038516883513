function Travel({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M47 41.2386V23.9392C47 20.6589 44.3417 18 41.0608 18H28.9392C25.6596 18 23 20.6589 23 23.9392V41.2386C23 43.7254 24.8097 45.7974 27.1813 46.2098L23.4566 51.8373H26.7683L28.2971 49.5267H41.7042L43.2329 51.8373H46.544L42.8199 46.2098C45.1909 45.7968 47 43.7254 47 41.2386ZM29.8571 20.6665H40.1429V22.3809H29.8571V20.6665ZM26.7624 26.2073C26.7624 25.4615 27.3663 24.8565 28.112 24.8565H41.8885C42.6337 24.8565 43.2386 25.4615 43.2386 26.2073V31.9045C43.2386 32.6502 42.6337 33.2546 41.8885 33.2546H28.1121C27.3663 33.2546 26.7625 32.6502 26.7625 31.9045V26.2073H26.7624ZM28.048 42.7173C27.1784 42.7173 26.4734 42.0123 26.4734 41.1432C26.4734 40.273 27.1784 39.5679 28.048 39.5679C28.9177 39.5679 29.6227 40.273 29.6227 41.1432C29.6227 42.0123 28.9177 42.7173 28.048 42.7173ZM29.2912 48.0247L30.4418 46.2854H39.5588L40.7094 48.0247H29.2912ZM41.9526 42.7173C41.083 42.7173 40.3779 42.0123 40.3779 41.1432C40.3779 40.273 41.083 39.5679 41.9526 39.5679C42.8222 39.5679 43.5273 40.273 43.5273 41.1432C43.5273 42.0123 42.8222 42.7173 41.9526 42.7173Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default Travel;

function TimeAlone({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const stroke = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M42.4375 43.0395C42.4371 41.6326 41.8856 40.282 40.9013 39.2768C39.917 38.2717 38.5781 37.6921 37.1716 37.6622C38.1283 37.1745 38.893 36.3789 39.3424 35.4036C39.7918 34.4282 39.8998 33.3301 39.649 32.2859C39.3982 31.2417 38.8032 30.3124 37.9598 29.6476C37.1165 28.9828 36.0739 28.6213 35 28.6213C33.9261 28.6213 32.8835 28.9828 32.0402 29.6476C31.1968 30.3124 30.6018 31.2417 30.351 32.2859C30.1002 33.3301 30.2082 34.4282 30.6576 35.4036C31.107 36.3789 31.8717 37.1745 32.8284 37.6622C31.4219 37.6921 30.083 38.2717 29.0987 39.2768C28.1144 40.282 27.5629 41.6326 27.5625 43.0395V50.9375H42.4375V43.0395Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M35.5312 22.25H34.4688V24.375H35.5312V22.25Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M41.2194 23.5593L39.7169 25.062L40.4682 25.8132L41.9706 24.3105L41.2194 23.5593Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M48.2812 49.875H46.1562V50.9375H48.2812V49.875Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M48.2812 44.5625H46.1562V45.625H48.2812V44.5625Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M48.2812 39.25H46.1562V40.3125H48.2812V39.25Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M47.5271 33.6287L45.5112 34.301L45.8474 35.309L47.8633 34.6367L47.5271 33.6287Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M45.879 28.1209L43.863 28.7932L44.1992 29.8012L46.2152 29.1289L45.879 28.1209Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M28.7805 23.5593L28.0293 24.3105L29.5319 25.8131L30.2831 25.0619L28.7805 23.5593Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M23.8438 49.875H21.7188V50.9375H23.8438V49.875Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M23.8438 44.5625H21.7188V45.625H23.8438V44.5625Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M23.8438 39.25H21.7188V40.3125H23.8438V39.25Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M22.4724 33.6289L22.1365 34.637L24.1525 35.3088L24.4885 34.3007L22.4724 33.6289Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M24.1207 28.1211L23.7847 29.1292L25.8007 29.8013L26.1368 28.7932L24.1207 28.1211Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M49.2114 22.1604C49.222 22.1868 49.2403 22.2095 49.2638 22.2255C49.2874 22.2415 49.3153 22.25 49.3438 22.25C49.3722 22.25 49.4001 22.2415 49.4237 22.2255C49.4473 22.2095 49.4655 22.1868 49.4761 22.1604C49.5895 21.8448 49.7713 21.5581 50.0085 21.321C50.2456 21.0838 50.5323 20.902 50.8479 20.7885C50.8743 20.778 50.897 20.7597 50.913 20.7362C50.929 20.7126 50.9375 20.6847 50.9375 20.6562C50.9375 20.6277 50.929 20.5999 50.913 20.5763C50.897 20.5527 50.8743 20.5345 50.8479 20.5239C50.5323 20.4105 50.2456 20.2287 50.0085 19.9915C49.7713 19.7544 49.5895 19.4677 49.4761 19.1521C49.4655 19.1257 49.4473 19.103 49.4237 19.087C49.4001 19.071 49.3722 19.0625 49.3438 19.0625C49.3153 19.0625 49.2874 19.071 49.2638 19.087C49.2403 19.103 49.222 19.1257 49.2114 19.1521C49.098 19.4677 48.9162 19.7544 48.679 19.9915C48.4419 20.2287 48.1553 20.4105 47.8397 20.5239C47.8132 20.5345 47.7905 20.5528 47.7745 20.5763C47.7585 20.5999 47.75 20.6278 47.75 20.6563C47.75 20.6847 47.7585 20.7126 47.7745 20.7362C47.7905 20.7598 47.8132 20.778 47.8397 20.7886C48.1553 20.902 48.4419 21.0838 48.679 21.321C48.9162 21.5581 49.098 21.8448 49.2114 22.1604Z"
        fill={stroke}
        className="transition"
      />
      <path
        d="M19.1521 20.7885C19.4678 20.902 19.7544 21.0838 19.9915 21.321C20.2287 21.5581 20.4105 21.8448 20.5239 22.1604C20.5345 22.1868 20.5527 22.2095 20.5763 22.2255C20.5999 22.2415 20.6278 22.25 20.6562 22.25C20.6847 22.25 20.7126 22.2415 20.7362 22.2255C20.7597 22.2095 20.778 22.1868 20.7886 22.1604C20.902 21.8448 21.0838 21.5581 21.321 21.321C21.5581 21.0838 21.8447 20.902 22.1603 20.7885C22.1868 20.778 22.2095 20.7597 22.2255 20.7362C22.2415 20.7126 22.25 20.6847 22.25 20.6562C22.25 20.6277 22.2415 20.5999 22.2255 20.5763C22.2095 20.5527 22.1868 20.5345 22.1603 20.5239C21.8447 20.4105 21.5581 20.2287 21.321 19.9915C21.0838 19.7544 20.902 19.4677 20.7886 19.1521C20.778 19.1257 20.7597 19.103 20.7362 19.087C20.7126 19.071 20.6847 19.0625 20.6562 19.0625C20.6278 19.0625 20.5999 19.071 20.5763 19.087C20.5527 19.103 20.5345 19.1257 20.5239 19.1521C20.4105 19.4677 20.2287 19.7544 19.9915 19.9915C19.7544 20.2287 19.4678 20.4105 19.1521 20.5239C19.1257 20.5345 19.103 20.5528 19.087 20.5763C19.071 20.5999 19.0625 20.6278 19.0625 20.6562C19.0625 20.6847 19.071 20.7126 19.087 20.7362C19.103 20.7597 19.1257 20.778 19.1521 20.7885Z"
        fill={stroke}
        className="transition"
      />
    </svg>
  );
}

export default TimeAlone;

function Eating({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M34.3016 28.5044C32.6207 28.5052 31.1062 29.1838 30.0027 30.2848C28.9017 31.3883 28.2231 32.9028 28.2223 34.5837C28.2231 36.2648 28.9017 37.7792 30.0027 38.8827C31.1062 39.9837 32.6207 40.6622 34.3016 40.6631C35.9827 40.6622 37.4971 39.9837 38.6006 38.8827C39.7016 37.7792 40.3801 36.2647 40.381 34.5837C40.3801 32.9028 39.7016 31.3883 38.6006 30.2848C37.4971 29.1838 35.9826 28.5052 34.3016 28.5044Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M34.3016 22C27.3523 22 21.7178 27.6346 21.7178 34.5839C21.7178 41.5332 27.3522 47.1677 34.3016 47.1677C41.2518 47.1677 46.8855 41.5332 46.8855 34.5839C46.8855 27.6346 41.2518 22 34.3016 22ZM34.3016 42.3601C30.0068 42.3592 26.5264 38.8787 26.5255 34.5839C26.5264 30.2891 30.0068 26.8087 34.3016 26.8078C38.5965 26.8087 42.0769 30.2891 42.0777 34.5839C42.0769 38.8787 38.5964 42.3592 34.3016 42.3601Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M14.8695 27.4672C13.3476 32.7098 13.0262 36.0155 13.0262 36.0155C13.0262 36.0155 12.8605 38.027 13.3626 38.027C13.8638 38.027 15.1065 38.027 15.1065 38.027V45.3177C15.1065 46.1039 15.7453 46.7427 16.5315 46.7427H17.6359C18.4221 46.7427 19.0608 46.1039 19.0608 45.3177V24.6155C19.0609 22.604 16.3783 22.2702 14.8695 27.4672Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M55.4752 23.4988H55.4719C54.9938 23.4988 54.9458 23.8865 54.9458 24.3646V29.8831H53.5597V24.3646C53.5597 23.8865 53.172 23.4988 52.6939 23.4988C52.2158 23.4988 51.8281 23.8865 51.8281 24.3646V29.8831H50.201V24.3646C50.201 23.8865 50.1529 23.4988 49.6749 23.4988H49.6716C49.008 23.4988 48.4695 24.0373 48.4695 24.7009V29.8831C48.4695 32.11 49.0503 32.7082 49.636 33.2939C50.2723 33.9303 50.5962 34.1423 50.5962 34.8847C50.5962 35.627 50.5962 38.0271 50.5962 38.0271V45.3178C50.5962 46.104 51.2351 46.7427 52.0212 46.7427H53.1256C53.9118 46.7427 54.5505 46.104 54.5505 45.3178V34.8847C54.5505 34.1423 54.8744 33.9303 55.5108 33.2939C56.0965 32.7082 56.6772 32.11 56.6772 29.8831V24.7009C56.6773 24.0373 56.1388 23.4988 55.4752 23.4988Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default Eating;

function Party({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle
        cx="35"
        cy="35"
        r="34.5"
        fill={circleFill}
        stroke="white"
        className="transition"
      />
      <path
        d="M40.3333 38.8484C38.5204 38.8484 37.0454 40.3234 37.0454 42.1363C37.0454 43.9492 38.5204 45.4242 40.3333 45.4242C42.1462 45.4242 43.6212 43.9492 43.6212 42.1363C43.6212 40.3234 42.1462 38.8484 40.3333 38.8484Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M40.3334 27.5757C39.5564 27.5757 38.9243 28.2078 38.9243 28.9848C38.9243 29.7617 39.5564 30.3938 40.3334 30.3938C41.1103 30.3938 41.7425 29.7617 41.7425 28.9848C41.7425 28.2078 41.1103 27.5757 40.3334 27.5757Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M30 21V52H50.6667V21H30ZM40.3333 24.7576C42.6642 24.7576 44.5606 26.6539 44.5606 28.9849C44.5606 31.3158 42.6642 33.2121 40.3333 33.2121C38.0024 33.2121 36.106 31.3158 36.106 28.9849C36.106 26.6539 38.0024 24.7576 40.3333 24.7576ZM40.3333 48.2424C36.9664 48.2424 34.2273 45.5032 34.2273 42.1364C34.2273 38.7696 36.9665 36.0303 40.3333 36.0303C43.7001 36.0303 46.4393 38.7696 46.4393 42.1364C46.4393 45.5032 43.7002 48.2424 40.3333 48.2424Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M28.6665 32.9697C27.146 32.9697 25.9089 34.2068 25.9089 35.7273C25.9089 37.2478 27.146 38.4849 28.6665 38.4849C30.187 38.4849 31.4241 37.2478 31.4241 35.7273C31.4241 34.2068 30.187 32.9697 28.6665 32.9697Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M28.6667 23.5151C28.015 23.5151 27.4849 24.0453 27.4849 24.6969C27.4849 25.3486 28.015 25.8787 28.6667 25.8787C29.3183 25.8787 29.8485 25.3486 29.8485 24.6969C29.8485 24.0453 29.3183 23.5151 28.6667 23.5151Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M20 18V44H33L37.5 21.5L37.3334 18H20ZM28.6666 21.1515C30.6216 21.1515 32.2121 22.742 32.2121 24.697C32.2121 26.6519 30.6216 28.2424 28.6666 28.2424C26.7117 28.2424 25.1212 26.6519 25.1212 24.697C25.1212 22.742 26.7117 21.1515 28.6666 21.1515ZM28.6666 40.8485C25.8428 40.8485 23.5455 38.5511 23.5455 35.7273C23.5455 32.9035 25.8429 30.6061 28.6666 30.6061C31.4904 30.6061 33.7878 32.9035 33.7878 35.7273C33.7878 38.5511 31.4905 40.8485 28.6666 40.8485Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default Party;

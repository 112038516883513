function Moon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8736 0.324549C27.3333 0.654765 26.4928 1.5835 26.8931 3.89501C27.1132 5.17459 27.2132 6.47482 27.1532 7.77504C26.9531 12.5838 24.812 16.9385 21.5102 19.9724C18.6087 22.6554 14.8266 24.3064 10.7445 24.327C8.46325 24.327 6.26209 23.8731 4.18098 22.9443C2.15991 22.0362 1.09935 22.676 0.659118 23.1301C0.198874 23.6048 -0.441466 24.7192 0.439001 26.9069C3.88082 35.348 12.0852 40.5695 20.8699 39.9504C29.1342 39.3312 36.198 33.3461 38.8594 25.4209C39.4997 23.5222 39.8799 21.5203 39.96 19.4564C39.98 19.1262 40 18.796 40 18.4658C40 11.5519 36.8383 5.0714 31.4555 0.984981C29.5945 -0.397798 28.4139 -0.0263052 27.8736 0.324549Z"
        fill="white"
      />
    </svg>
  );
}

export default Moon;

function Sun() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 34C21.1046 34 22 34.8954 22 36V38C22 39.1046 21.1046 40 20 40C18.8954 40 18 39.1046 18 38V36C18 34.8954 18.8954 34 20 34ZM32.728 29.8996L34.1422 31.3138C34.9232 32.0948 34.9232 33.361 34.1422 34.1422C33.3612 34.9232 32.0948 34.9232 31.3138 34.1422L29.8996 32.728C29.1186 31.9468 29.1186 30.6806 29.8996 29.8996C30.6806 29.1184 31.947 29.1184 32.728 29.8996ZM7.27197 29.8996C8.05302 29.1184 9.31936 29.1184 10.1004 29.8996C10.8214 30.6205 10.8768 31.755 10.2668 32.5396L10.1004 32.728L8.68618 34.1422C7.90514 34.9232 6.6388 34.9232 5.85776 34.1422C5.13678 33.4213 5.08132 32.2868 5.69138 31.5022L5.85776 31.3138L7.27197 29.8996ZM20 8C26.6274 8 32 13.3726 32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8ZM4 18C5.10456 18 6 18.8954 6 20C6 21.0257 5.22791 21.871 4.23324 21.9865L4 22H2C0.89544 22 0 21.1046 0 20C0 18.9743 0.772089 18.129 1.76676 18.0135L2 18H4ZM38 18C39.1046 18 40 18.8954 40 20C40 21.1046 39.1046 22 38 22H36C34.8954 22 34 21.1046 34 20C34 18.8954 34.8954 18 36 18H38ZM5.85782 5.85776C6.57878 5.1368 7.71325 5.08134 8.49783 5.69138L8.68624 5.85776L10.1005 7.27198C10.8815 8.05302 10.8815 9.31936 10.1005 10.1004C9.37948 10.8214 8.24503 10.8768 7.46044 10.2668L7.27203 10.1004L5.85782 8.68618C5.07676 7.90514 5.07676 6.6388 5.85782 5.85776ZM34.1422 5.8578C34.9232 6.63884 34.9232 7.90518 34.1422 8.68622L32.728 10.1004C31.9468 10.8815 30.6806 10.8815 29.8996 10.1004C29.1184 9.31938 29.1184 8.05306 29.8996 7.27201L31.3138 5.8578C32.0948 5.07674 33.361 5.07674 34.1422 5.8578ZM20 0C21.1046 0 22 0.89544 22 2V4C22 5.10456 21.1046 6 20 6C18.8954 6 18 5.10456 18 4V2C18 0.89544 18.8954 0 20 0Z"
        fill="white"
      />
    </svg>
  );
}

export default Sun;

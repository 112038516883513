function Cleaning({ active, onClick }) {
  const circleFill = active ? "#ffffff" : "#000000";
  const pathFill = active ? "#000000" : "#ffffff";

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-selection"
      onClick={onClick}
    >
      <circle cx="35" cy="35" r="34.5" fill={circleFill} stroke="white" />
      <path
        d="M32.2187 51.9999C31.873 52.0017 31.5351 51.8979 31.25 51.7025C30.9649 51.507 30.7462 51.2292 30.6232 50.9062L27.8998 43.8242C27.8446 43.6815 27.7602 43.5519 27.652 43.4437C27.5438 43.3355 27.4142 43.2511 27.2715 43.1959L20.1874 40.4705C19.8648 40.3466 19.5872 40.1278 19.3914 39.843C19.1956 39.5581 19.0908 39.2206 19.0908 38.8749C19.0908 38.5293 19.1956 38.1918 19.3914 37.9069C19.5872 37.6221 19.8648 37.4033 20.1874 37.2794L27.2695 34.556C27.4122 34.5008 27.5418 34.4165 27.65 34.3083C27.7582 34.2001 27.8425 34.0705 27.8977 33.9278L30.6232 26.8437C30.747 26.521 30.9658 26.2434 31.2507 26.0477C31.5355 25.8519 31.8731 25.7471 32.2187 25.7471C32.5643 25.7471 32.9019 25.8519 33.1867 26.0477C33.4716 26.2434 33.6904 26.521 33.8142 26.8437L36.5376 33.9257C36.5928 34.0684 36.6772 34.198 36.7854 34.3062C36.8936 34.4144 37.0232 34.4988 37.1659 34.554L44.2069 37.263C44.5427 37.3875 44.832 37.6125 45.0354 37.9072C45.2389 38.202 45.3465 38.5523 45.3437 38.9105C45.3385 39.2501 45.2314 39.5804 45.0364 39.8585C44.8414 40.1366 44.5675 40.3498 44.2499 40.4705L37.1679 43.1939C37.0252 43.2491 36.8956 43.3334 36.7874 43.4416C36.6792 43.5498 36.5949 43.6794 36.5397 43.8221L33.8142 50.9062C33.6912 51.2292 33.4725 51.507 33.1874 51.7025C32.9023 51.8979 32.5644 52.0017 32.2187 51.9999Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M24.0159 29.0316C23.8132 29.0315 23.6153 28.9701 23.4483 28.8554C23.2812 28.7407 23.1528 28.5781 23.08 28.389L21.9275 25.3921C21.9025 25.3266 21.864 25.267 21.8144 25.2174C21.7648 25.1678 21.7052 25.1293 21.6397 25.1043L18.6428 23.9518C18.4537 23.8789 18.2911 23.7505 18.1764 23.5835C18.0618 23.4164 18.0004 23.2186 18.0004 23.0159C18.0004 22.8133 18.0618 22.6154 18.1764 22.4484C18.2911 22.2813 18.4537 22.1529 18.6428 22.0801L21.6397 20.9275C21.7052 20.9025 21.7646 20.8639 21.8142 20.8143C21.8638 20.7647 21.9024 20.7052 21.9275 20.6398L23.0698 17.6695C23.1342 17.4947 23.245 17.3407 23.3904 17.2241C23.5358 17.1075 23.7102 17.0327 23.8949 17.0078C24.1166 16.9809 24.341 17.0287 24.5324 17.1437C24.7239 17.2588 24.8714 17.4344 24.9517 17.6429L26.1042 20.6398C26.1293 20.7052 26.1679 20.7647 26.2175 20.8143C26.2671 20.8639 26.3266 20.9025 26.392 20.9275L29.3889 22.0801C29.578 22.1529 29.7406 22.2813 29.8553 22.4484C29.97 22.6154 30.0314 22.8133 30.0314 23.0159C30.0314 23.2186 29.97 23.4164 29.8553 23.5835C29.7406 23.7505 29.578 23.8789 29.3889 23.9518L26.392 25.1043C26.3265 25.1293 26.267 25.1678 26.2174 25.2174C26.1678 25.267 26.1292 25.3266 26.1042 25.3921L24.9517 28.389C24.8789 28.5781 24.7505 28.7407 24.5835 28.8554C24.4164 28.9701 24.2185 29.0315 24.0159 29.0316Z"
        fill={pathFill}
        className="transition"
      />
      <path
        d="M45.3437 34.4999C45.1227 34.4999 44.9068 34.4328 44.7246 34.3076C44.5424 34.1824 44.4024 34.0049 44.3231 33.7986L42.7618 29.7401C42.7343 29.6685 42.6921 29.6036 42.638 29.5494C42.5838 29.4953 42.5189 29.4531 42.4473 29.4256L38.3888 27.8643C38.1827 27.7848 38.0054 27.6448 37.8803 27.4626C37.7553 27.2804 37.6884 27.0646 37.6884 26.8437C37.6884 26.6227 37.7553 26.4069 37.8803 26.2247C38.0054 26.0426 38.1827 25.9025 38.3888 25.8231L42.4473 24.2617C42.5189 24.2343 42.5838 24.1921 42.638 24.1379C42.6921 24.0837 42.7343 24.0188 42.7618 23.9473L44.3115 19.9175C44.3822 19.7269 44.5033 19.5591 44.6619 19.4319C44.8204 19.3046 45.0104 19.2228 45.2118 19.1949C45.4537 19.1657 45.6986 19.218 45.9074 19.3437C46.1162 19.4694 46.277 19.6613 46.3643 19.8888L47.9257 23.9473C47.9531 24.0188 47.9953 24.0837 48.0495 24.1379C48.1037 24.1921 48.1686 24.2343 48.2401 24.2617L52.2986 25.8231C52.5048 25.9025 52.6821 26.0426 52.8071 26.2247C52.9322 26.4069 52.9991 26.6227 52.9991 26.8437C52.9991 27.0646 52.9322 27.2804 52.8071 27.4626C52.6821 27.6448 52.5048 27.7848 52.2986 27.8643L48.2401 29.4256C48.1686 29.4531 48.1037 29.4953 48.0495 29.5494C47.9953 29.6036 47.9531 29.6685 47.9257 29.7401L46.3643 33.7986C46.285 34.0049 46.145 34.1824 45.9629 34.3076C45.7807 34.4328 45.5648 34.4999 45.3437 34.4999Z"
        fill={pathFill}
        className="transition"
      />
    </svg>
  );
}

export default Cleaning;
